import type {ReactNode} from 'react';

type WaveProps = {
  isPurpleTop: boolean;
};

function shuffle(array: ReactNode[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export default function Wave(props: WaveProps) {
  const {isPurpleTop} = props;
  const waves = shuffle([
    <path
      d="M365.849 22.576C431.872 18.26 495.801 10.524 561.642 5.832C655.43 -0.855999 753.099 -1.26 846.738 5.676C937.736 12.4 1032.09 28.8 1122.01 37.132C1201.75 44.524 1270.5 51 1366 38.332C1366 38.332 1473 27 1518 19.166C1557.18 12.3448 1620.5 5.53616 1665 5.53616C1709.5 5.53616 1670 0 1670 0H0V10.94C116.64 26.4817 243.163 30.5058 365.849 22.576Z"
      fill={isPurpleTop ? '#F0F0FC' : 'white'}
      key="1"
    />,
    <path
      d="M2964.15 22.576C2898.13 18.26 2834.2 10.524 2768.36 5.832C2674.57 -0.855999 2576.9 -1.26 2483.26 5.676C2392.26 12.4 2297.91 28.8 2207.99 37.132C2128.25 44.524 2059.5 51 1964 38.332C1964 38.332 1857 27 1812 19.166C1772.82 12.3448 1695.5 5.44025 1665 5.53616C1634.5 5.63207 1660 0 1660 0H3330C3330 0 3407 10.94 3330 10.94C3253 10.94 3086.84 30.5058 2964.15 22.576Z"
      fill={isPurpleTop ? '#F0F0FC' : 'white'}
      key="2"
    />,
    <path
      d="M3695.85 22.576C3761.87 18.26 3825.8 10.524 3891.64 5.832C3985.43 -0.855999 4083.1 -1.26 4176.74 5.676C4267.74 12.4 4362.09 28.8 4452.01 37.132C4531.75 44.524 4600.5 51 4696 38.332C4696 38.332 4803 27 4848 19.166C4887.18 12.3448 4964.5 5.44025 4995 5.53616C5025.5 5.63207 5000 0 5000 0H3330C3330 0 3284.5 10.9398 3330 10.94C3375.5 10.9402 3573.16 30.5058 3695.85 22.576Z"
      fill={isPurpleTop ? '#F0F0FC' : 'white'}
      key="3"
    />,
  ]);

  return (
    <div
      aria-hidden
      className="left-0 h-full w-full overflow-x-hidden overflow-y-hidden"
    >
      <svg
        className="relative my-[-1px] block h-full w-fit"
        fill="none"
        height="46"
        viewBox="0 0 5010 46"
        width="5010"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6374_30785)">
          <rect
            fill={isPurpleTop ? 'white' : '#F0F0FC'}
            height="46"
            transform="matrix(1 0 0 -1 0 46)"
            width="5010"
          />
          {waves.map((wave) => wave)}
        </g>
        <defs>
          <clipPath id="clip0_6374_30785">
            <rect
              fill={isPurpleTop ? 'white' : '#F0F0FC'}
              height="46"
              transform="matrix(1 0 0 -1 0 46)"
              width="5010"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
